import React from "react";
var __jsx = React.createElement;
import Layout from "../components/Layout";

var NotFound = function NotFound() {
  return __jsx("div", {
    className: "notfound "
  }, __jsx("div", {
    className: "forbidden"
  }, __jsx("div", {
    className: "text"
  }, "Not Found")), __jsx("div", {
    className: "text-404"
  }, "404"), __jsx("div", {
    className: "door"
  }), __jsx("div", {
    id: "hodor"
  }, __jsx("div", {
    className: "l-arm"
  }, __jsx("div", {
    className: "hand"
  }), __jsx("div", {
    className: "arm"
  })), __jsx("div", {
    className: "r-arm"
  }, __jsx("div", {
    className: "hand"
  }), __jsx("div", {
    className: "arm"
  })), __jsx("div", {
    className: "body"
  }, __jsx("div", {
    className: "backpack-strap-r"
  }), __jsx("div", {
    className: "backpack-strap-l"
  })), __jsx("div", {
    className: "belt"
  }), __jsx("div", {
    className: "head"
  }, __jsx("div", {
    className: "hair"
  }), __jsx("div", {
    className: "face"
  }), __jsx("div", {
    className: "r-eye"
  }), __jsx("div", {
    className: "l-eye"
  }), __jsx("div", {
    className: "r-brow"
  }), __jsx("div", {
    className: "l-brow"
  }), __jsx("div", {
    className: "beard"
  }), __jsx("div", {
    className: "bubble"
  }, "H0D0R!")), __jsx("div", {
    className: "l-leg"
  }, __jsx("div", {
    className: "foot"
  })), __jsx("div", {
    className: "r-leg"
  }, __jsx("div", {
    className: "foot"
  }))));
};

NotFound.getLayout = function getLayout(page) {
  return __jsx(Layout, {
    title: "not-found",
    navTitle: "not-found"
  }, page);
};

export default NotFound;